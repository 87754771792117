import React, { useRef, useEffect, useState } from 'react'
import { errorMap } from './BaseComponent'
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import Modal from './Modal'
import ReactPaginate from 'react-paginate';
import { parseDate } from './BaseComponent'
import { API } from './BaseComponent'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table'
import _ from 'lodash'
import "./ListTable.scss"
import {ReactComponent as OrderIcon} from "../../img/order.svg"
import {ReactComponent as OptionsIcon} from "../../img/options.svg"
import {ReactComponent as DeleteIcon} from "../../img/delete.svg"
import {ReactComponent as EditIcon} from "../../img/edit.svg"
import {ReactComponent as SettingsIcon} from "../../img/settings.svg"
import {ReactComponent as ViewIcon} from "../../img/view.svg"
import {ReactComponent as AddIcon} from "../../img/add.svg"

const Pagination = ({count, limit, page, setPage, setLimit}) => {
  const pages = Math.ceil(count/limit)

  return (
    <div className='pagination-outer'>
      <div className="count">{count} Results</div>
      <div className="limit">
        <span>Show: </span>
        <select name="limit" value={limit} onChange={ (e) => { setLimit(e.target.value) } }>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={30}>30</option>
          <option value={35}>35</option>
          <option value={40}>40</option>
        </select>
      </div>
      { pages > 0 ? <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={ setPage }
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={ page }
      /> : null }
    </div>
  )
}

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};

const SearchBox = ({search, setSearch, searchCallback}) => {
  const delaySearchCallback = useDebounce((term) => {
    searchCallback(term)
  }, 500);

  return (
    <div className="search-box">
      <div className="search-input">
        <input type="text" placeholder="Type to search" value={ search } onChange={ (e) => {
          setSearch(e.target.value)
          delaySearchCallback(e.target.value)
        } }/>
      </div>
    </div>
  )
}

export default function ListTable ({data}) {
  const navigate = useNavigate()
  const [preloader, setPreloader] = useState(true)
  const [page, setPage] = useState(0)
  const [results, setResults] = useState([])
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState("")
  const [confirm, setConfirm] = useState(false)
  const [tabFilter, setTabFilter] = useState(localStorage.getItem(`${data.table}TabFilter`) ? JSON.parse(localStorage.getItem(`${data.table}TabFilter`)) : 0)
  const [userFilter, setUserFilter] = useState(localStorage.getItem(`${data.table}UserFilter`) ? JSON.parse(localStorage.getItem(`${data.table}UserFilter`)) : false)
  const [orderBy, setOrderBy] = useState("created-desc")
  const [filters, setFilters] = useState({})
  const [showCustomize, setShowCustomize] = useState(false)
  const [uuid, setUUID] = useState(null)
  const [mapUUID, setMapUUID] = useState("")
  const [searchParams] = useSearchParams()

  const Sort = ({sortBy, name, setOrderBy, orderBy}) => {
    const [show, setShow] = useState(false)

    return <div className={ show ? "sort-settings active" : "sort-settings" }>
      <button className={`sort-button ${orderBy.includes(sortBy) ? "active" : ""} ${orderBy.includes(sortBy+"-asc") ? "asc" : ""} ${orderBy.includes(sortBy+"-desc") ? "desc" : ""}`} onClick={() => { setShow(!show) }}><span>{name}</span><OrderIcon/></button>
      <ul>
        <li onClick={() => { setOrderBy(sortBy+"-asc"); setShow(false) }} className={ sortBy+"-asc" === orderBy ? "active" : "" }><span>Sort Ascending</span></li>
        <li onClick={() => { setOrderBy(sortBy+"-desc"); setShow(false) }} className={ sortBy+"-desc" === orderBy ? "active" : "" }><span>Sort Descending</span></li>
        <li onClick={() => { setOrderBy(""); setShow(false) }} className={ "" === orderBy ? "active" : "" }><span>Remove Sorting</span></li>
      </ul>
    </div>
  }

  const Selectable = ({name, filters, selectedFilters, setFilters}) => {
    const [show, setShow] = useState(false)

    const changeFilter = (filter) => {
      let tempFilters = {...selectedFilters}
      if(tempFilters[Object.keys(filter)[0]] || tempFilters[Object.keys(filter)[0]] === false) {
        delete tempFilters[Object.keys(filter)[0]]
      } else {
        tempFilters = {...tempFilters, ...filter}
      }
      setFilters(tempFilters)
    }

    return <div className={ show ? "sort-settings active" : "sort-settings" }>
      <button className={`sort-button`} onClick={() => { setShow(!show) }}><span>{name}</span><OrderIcon/></button>
      <ul>
        {filters.map((filter, i) => {
          return (
            <li key={i}>
              <label>
                <input
                  {...{
                    type: 'checkbox',
                    checked: selectedFilters[filter],
                    onChange: () => {
                        changeFilter(filter.params)
                    }
                  }}
                />{' '}
                {filter.name}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const Options = ({uuid, setConfirm, additionalOptions, row}) => {
    const optionsMap = {
      showStoriesInGroup: {
        name: "See stories in the group",
        icon: <ViewIcon/>,
        path: "/story?group=",
        value: "ident"
      },
      addStoryToGroup: {
        name: "Add story to this group",
        icon: <AddIcon/>,
        path: "/story/new?group=",
        value: "ident"
      },
      showFaqInCategory: {
        name: "See FAQ's from this category",
        icon: <ViewIcon/>,
        path: "/faq?category=",
        value: "slug"
      },
      addFaqToCategory: {
        name: "Add FAQ to this category",
        icon: <AddIcon/>,
        path: "/faq/new?category=",
        value: "slug"
      }
    }

    return (
      <div className="options">
        <button><OptionsIcon/></button>
        <ul>
          <li><EditIcon/><span>Edit</span></li>
          <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setConfirm(true); setUUID(uuid) }}><DeleteIcon/><span>Delete</span></li>
          { additionalOptions?.map((option, i) => {
            return (
              <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate(optionsMap[option].path+row[optionsMap[option].value]) }} key={i}>{optionsMap[option].icon}<span>{optionsMap[option].name}</span></li>
            )
          }) }
        </ul>
      </div>
    )
  }

  const defaultColumns = data.tableColumns.map((item) => {
    return {
      accessorKey: item.value,
      cell: (info) => {
        if(item.type === "date") {
          return info.getValue() ? parseDate(info.getValue(), true) : "nd"
        }if(item.type === "image") {
          return info.getValue() ? <img src={info.getValue()} alt=""/> : ""
        } else if(item.type === "state") {
          let flags = []
          item.flags.forEach((flag) => {
            if(flag === "public") {
              if(info.row.original[flag]) {
                flags.push("published")
              } else {
                flags.push("unpublished")
              }
            } else {
              if(info.row.original[flag]) flags.push(flag)
            }
          })
          return <ul className="flags">{flags.map((fl, i) => { return (<li key={i} className={fl}>{capitalizeFirstLetter(fl)}</li>) })}</ul>
        } else if(item.type === "options") {
          return <Options uuid={info.row.original.uuid} setConfirm={setConfirm} setUUID={setUUID} additionalOptions={data.additionalOptions} row={info.row.original}/>
        } else {
          return info.getValue() || null
        }
      },
      header: (column) => {
        if(item.sortable) {
          return <Sort sortBy={item.value} name={item.name} setOrderBy={setOrderBy} orderBy={column.table.getState().orderBy}/>
        } else if(item.selectable && item.selectable.length > 0) {
          return <Selectable name={item.name} filters={item.selectable} selectedFilters={column.table.getState().filters} setFilters={setFilters}/>
        } else {
          return <span>{item.name}</span>
        }
      },
      id: item.type === "options" ? "options" : item.value,
      name: item.name,
      footer: props => props.column.id,
    }
  })

  let defaultHiddenColumns = {}

  data.tableColumns.forEach((item) => {
    if(item.defaultHidden) {
      defaultHiddenColumns[item.value] = false
    }
  })

  const [columns] = useState(() => [...defaultColumns])
  const [columnVisibility, setColumnVisibility] = useState(localStorage.getItem(`${data.table}Visibility`) ? JSON.parse(localStorage.getItem(`${data.table}Visibility`)) : defaultHiddenColumns)

  useEffect(() => {
    localStorage.setItem(`${data.table}Visibility`, JSON.stringify(columnVisibility))
    //eslint-disable-next-line
  }, [columnVisibility])

  useEffect(() => {
    localStorage.setItem(`${data.table}UserFilter`, JSON.stringify(userFilter))
    //eslint-disable-next-line
  }, [userFilter])

  useEffect(() => {
    localStorage.setItem(`${data.table}TabFilter`, JSON.stringify(tabFilter))
    //eslint-disable-next-line
  }, [tabFilter])

  useEffect(() => {
    if(data.tabFilters && !localStorage.getItem(`${data.table}TabFilter`)) {
      data.tabFilters.forEach((item, key) => {
        if(item.default) {
          setTabFilter(key)
        }
      })
    }
    //eslint-disable-next-line
  }, [])

  const table = useReactTable({
    data: results,
    columns,
    state: {
      columnVisibility,
      orderBy,
      filters
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    renderFallbackValue: "n/d"
  })

  const handleSetPage = (data) => {
    setPreloader(true)
    setPage(data.selected)
  }

  const getData = (uuid) => {
    let url = API+data.endpointList

    let params = {
      offset: page*limit,
      limit: limit
    }

    if(search) {
      params.query = search
    }

    if(data.additionalParams) {
      params = {...params, ...data.additionalParams}
    }

    if(data.tabFilters) {
      const selectedFilter = data.tabFilters[tabFilter]
      params = {...params, ...selectedFilter.params}
    }

    if(data.sortAsUser && userFilter) {
      params.orderBy = "user"
    } else if(orderBy) {
      params.orderBy = orderBy
    }

    if(uuid) {
      params.map = { uuid: uuid }
    }

    if(searchParams.get("category")) {
      params.category = searchParams.get("category")
    }

    if(searchParams.get("group")) {
      params.group = searchParams.get("group")
    }

    params = {...params, ...filters}

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
        setPreloader(false)
      } else {
        setCount(result?.data?.count)
        setResults(result.data[data.content] || result.data || [])
        setPreloader(false)
      }
    }, (error) => {
      console.log(error)
      setPreloader(false)
    })
  }

  const getLatestMap = () => {
    let url = API+"/map/list"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        const geocoded = _.filter(result.data?.maps, {geocoded: true, archived: false})
        const lastMap = geocoded[0]
        setMapUUID(lastMap?.uuid)
        getData(lastMap?.uuid)
      }
    }, (error) => {
      console.log(error)
    })
  }

  const deleteItem = (id) => {
    setPreloader(true)
    let url = API+data.endpointDelete

    const params = {
      [data.table]: {
        uuid: id
      }
    }
    
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
        setPreloader(false)
        setConfirm(false)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie usunięto."}}), true);
        getData(mapUUID)
        setPreloader(false)
        setConfirm(false);
      }
      setUUID(null)
    }, (error) => {
      console.log(error)
      setPreloader(false)
      setUUID(null)
    })
  }

  useEffect(() => {
    if(data.table === "address") {
      getLatestMap()
    } else {
      getData(mapUUID)
    }
    //eslint-disable-next-line
  }, [page, limit, tabFilter, userFilter, orderBy, filters])

  const Confirm = (
    <div className="confirm">
      <h4>Are you sure?</h4>
      <div className="btn-holder">
        <button className="btn" onClick={ () => { setConfirm(false); setUUID(null) } }><span>No</span></button>
        <button className="btn" onClick={ () => deleteItem(uuid) }><span>Yes</span></button>
      </div>
    </div>
  )

  return (
    <section className='list list-table'>
      <div className="container">
        { preloader ? <div className="preloader"><span></span></div> : null }
        <div className="list-header">
          <div className="list-title">
            <h1>{data.name}</h1>
            { data.add && <NavLink className="btn" to={data.new}><span>{data.add}</span></NavLink> }
          </div>
          { data.additionalMenu && <div className="additional-menu">
            <ul>
              { data.additionalMenu.map((item, i) => {
                return (
                  <li key={i}>
                    <Link to={item.url}>{item.name}</Link>
                  </li>
                )
              }) }
            </ul>
          </div> }
        </div>
        <div className="list-top">
          <SearchBox search={search} setSearch={setSearch} searchCallback={getData}/>
          <Pagination count={count} page={page} limit={limit} setLimit={setLimit} setPage={handleSetPage}/>
        </div>
        <div className="table-outer">
          <div className="table-top">
            { data.tabFilters && data.tabFilters.length > 0 && <div className="tab-filters">
              <ul className="tab-filters-list">
                { data.tabFilters.map((item, i) => {
                  return (
                    <li key={i} onClick={() => { setTabFilter(i) }} className={tabFilter === i ? "btn active" : "btn"}>
                      <span>{item.name}</span>
                    </li>
                  )
                }) }
              </ul>
            </div> }
            <div className="table-options">
              { data.sortAsUser && <div className="sort-as-user">
                <label>
                  <input
                    {...{
                      type: 'checkbox',
                      checked: userFilter,
                      onChange: () => {
                          setUserFilter(!userFilter)
                      }
                    }}
                  />{' '}
                  Sort as the user sees
                </label>
                <div className="info-icon">
                  <span>i</span>
                  <div className="info-description">
                    <div className="info-description-content" dangerouslySetInnerHTML={{ __html: data.sortAsUserDesc }}></div>
                  </div>
                </div>
              </div> }
              <div className="customize-table">
                <button className='btn' onClick={() => { setShowCustomize(!showCustomize) }}><span>Customize display</span><SettingsIcon/></button>
                <div className={showCustomize ? 'customize-options show' : 'customize-options'}>
                  <div>
                    <label>
                      <input
                        {...{
                          type: 'checkbox',
                          checked: table.getIsAllColumnsVisible(),
                          onChange: table.getToggleAllColumnsVisibilityHandler(),
                        }}
                      />{' '}
                      Toggle All
                    </label>
                  </div>
                  {table.getAllLeafColumns().map(column => {
                    if(column.id && column.columnDef.name !== "Options") {
                      return (
                        <div key={column.id} className="px-1">
                          <label>
                            <input
                              {...{
                                type: 'checkbox',
                                checked: column.getIsVisible(),
                                onChange: column.getToggleVisibilityHandler(),
                              }}
                            />{' '}
                            {column.columnDef.name}
                          </label>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="table-inner">
            <table cellSpacing="0">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                  <tr key={row.id} onClick={() => { navigate(data.route+"/"+row.original.uuid) }}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell?.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                )})}
              </tbody>
            </table>
          </div>
        </div>
        <div className="list-footer">
          <Pagination count={count} page={page} limit={limit} setLimit={setLimit} setPage={handleSetPage}/>
        </div>
      </div>
      <Modal open={ confirm } hidePopup={ () => setConfirm(false) }>{ Confirm }</Modal>
    </section>
  )
}